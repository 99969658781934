<template>
  <div class="page page__ueber">
    <HeaderOne
      :imgpath="'assets/img/03_reservieren/header/21_06_07_THI_1171.jpg'"
      :imgposition="'center 60%'"
    />
    <IntroData class="section" />
    <TheFooter class="section" />
  </div>
</template>

<script>
import HeaderOne from "@/components/common/header/HeaderOne";
import IntroData from "@/components/common/introblock/IntroData.vue";
import TheFooter from "../components/common/footer/TheFooter.vue";
export default {
  components: {
    HeaderOne,
    IntroData,
    TheFooter,
  },
};
</script>
<style
  lang="
    scss"
></style>
